import React, { FC, useContext } from "react";
import Text from "../components/Text";
import { LangContext } from "../context";
import { ExplanationKey, LangPrefix } from "../enums";

export interface ExplanationProps {
  k?: ExplanationKey;
}

export const Explanation: FC<ExplanationProps> = ({ k }) => {
  const { langPrefix } = useContext(LangContext);
  const ko = langPrefix === LangPrefix.Ko;
  const cht = langPrefix === LangPrefix.Cht;

  switch (k) {
    case ExplanationKey.NoKo:
      return (
        <Text.Body className="mb-3 text-gray-common">
          해당 페이지는 아직 한국어 지원이 완전하지 않습니다.
        </Text.Body>
      );
    case ExplanationKey.NoCht:
      return (
        <Text.Body className="mb-3 text-gray-common">中文翻譯進行中</Text.Body>
      );
    case ExplanationKey.S3Prologue:
      return ko ? (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>
              시즌 3 프롤로그는 특별한 엔딩이 없습니다. 따라서 어떤 선택지를
              고르든 무관합니다.
            </li>
            <li>마지막 스토리 이후 초기화를 할 수 있습니다.</li>
          </Text.Body>
        </ul>
      ) : cht ? (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>第3季序幕沒有特定結局，可按喜好選擇任意選項。</li>
            <li>最後一個故事結束後可以重置該DLC。</li>
          </Text.Body>
        </ul>
      ) : (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>
              Season 3 prologue does not have any endings, so you may choose
              whatever choice you want.
            </li>
            <li>You can reset the DLC after the last story.</li>
          </Text.Body>
        </ul>
      );
    case ExplanationKey.NeverForget:
      return ko ? (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>
              'We never forget you' DLC는 특별한 엔딩이 없습니다. 따라서 어떤
              선택지를 고르든 무관합니다.
            </li>
            <li>마지막 스토리 이후 초기화를 할 수 있습니다.</li>
          </Text.Body>
        </ul>
      ) : cht ? (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>
              《We never forget you》DLC沒有特定結局，可按喜好選擇任意選項。
            </li>
            <li>最後一個故事結束後可以重置該DLC。</li>
          </Text.Body>
        </ul>
      ) : (
        <ul className="list-disc">
          <Text.Body className="text-gray-common pl-5 self-start text-base mb-3">
            <li>
              'We never forget you' DLC does not have any endings, so you may
              choose whatever choice you want.
            </li>
            <li>You can reset the DLC after the last story.</li>
          </Text.Body>
        </ul>
      );
    case ExplanationKey.InitializeMidnight:
      return ko ? (
        <Text.Body className="mb-3 text-gray-common">
          모든 채팅에 참여하기 위해서는 00:11 이전에 게임을 초기화해야 합니다.
        </Text.Body>
      ) : cht ? (
        <Text.Body className="mb-3 text-gray-common w-full">
          如果想要參與所有聊天室，須在00:11之前初始化遊戲
        </Text.Body>
      ) : (
        <Text.Body className="mb-3 text-gray-common">
          To join every chatrooms, you should initialize the game before 00:11.
        </Text.Body>
      );
    case ExplanationKey.Progress:
      return ko ? (
        <Text.Body className="mb-3 text-gray-common">
          현재 공략 작성 중입니다.
        </Text.Body>
      ) : (
        <Text.Body className="mb-3 text-gray-common">
          This walkthough is still in progress.
        </Text.Body>
      );
    case ExplanationKey.WilliamCE:
      return ko ? (
        <Text.Body className="mb-3 text-gray-common">
          윌리엄 루트는 황당엔딩을 피하는 것이 어렵습니다. 그렇기에 17일차까지
          진행을 하고 싶으시다면{" "}
          <a href="/ko/william/day14#lets-do-our-own-jobs">황당엔딩3</a>을
          피하기 위한 팁을 확인하고 시작하시는 것을 추천드립니다.
        </Text.Body>
      ) : cht ? (
        <Text.Body className="mb-3 text-gray-common">
          在William路線中避開CE（荒誕結局）稍有難度。如果想順利抵達Day17，建議在開始這條路線之前先行查看
          <a
            href="/zh-tw/william/day14#lets-do-our-own-jobs"
            className="underline"
          >
            CE3
          </a>
          的提示。
        </Text.Body>
      ) : (
        <Text.Body className="mb-3 text-gray-common">
          In William's route, it is hard to avoid all CEs. Therefore, if you
          want to progress to day17, I recommend you to check the tip on{" "}
          <a href="/william/day14#lets-do-our-own-jobs" className="underline">
            CE3
          </a>{" "}
          before you start.
        </Text.Body>
      );
    default:
      return null;
  }
};
