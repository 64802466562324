import React, { FC, useContext } from "react";
import { PageProps } from "gatsby";
import { Album, Photo } from "../types";
import { Title } from "../components/Title";
import { Body } from "../components/Body";
import { PhotoCard } from "../components/Card";
import { Divider } from "../components/Divider";
import { Seo } from "../components/Seo";
import { BackgroundFromKey } from "../components/Background";
import { Explanation } from "../data/Explanation";
import { LangContext } from "../context";
import {
  explanationOfAlbum,
  textOfPhoto,
  titleOfAlbum,
  whereOfPhoto,
} from "../langUtils";

const AlbumTemplate: FC<PageProps> = ({ pageContext }) => {
  const album = pageContext as unknown as Album;
  const { langPrefix } = useContext(LangContext);
  const explanation = explanationOfAlbum(langPrefix, album);

  return (
    <>
      <BackgroundFromKey k={album.background} />
      <Seo
        title={album.shortTitle}
        description={album.photos
          .map((photo) => {
            const text = textOfPhoto(langPrefix, photo);
            const where = whereOfPhoto(langPrefix, photo);
            return text + (where ? ` - ${where}` : "");
          })
          .join(", ")}
      />
      <Body>
        <Title text={titleOfAlbum(langPrefix, album)} className={album.color} />
        <Divider />
        <Explanation k={explanation} />
        <div className="mt-2 grid grid-cols-3 gap-2 mx-2 w-full">
          {album.photos.map((photo: Photo, idx: number) => (
            <PhotoCard key={idx} data={photo} idx={idx} />
          ))}
        </div>
      </Body>
    </>
  );
};

export default AlbumTemplate;
