import React, { FC, HTMLAttributes, useContext } from "react";
import { Card as MuiCard, Divider } from "@mui/material";
import Text from "./Text";
import { ChatItem, Story, Photo } from "../types";
import { LangContext, NavbarContext } from "../context";
import { ChatItemType, LangPrefix, StoryType } from "../enums";
import { storyTypeToLang, textOfPhoto, whereOfPhoto } from "../langUtils";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {}

export const Card: FC<CardProps> = ({
  className = "",
  children,
}: CardProps) => (
  <MuiCard
    variant="outlined"
    sx={{
      borderWidth: 1,
      borderColor: "rgba(113, 113, 113, 0.7)",
    }}
    className={`flex-grow overflow-x-auto ${className}`}
  >
    {children}
  </MuiCard>
);

enum LiStyle {
  Disc = "disc",
  Inequal = ">",
}

interface CardTextProps extends HTMLAttributes<HTMLElement> {
  depth?: number;
  liStyle?: LiStyle;
}

const CardText: FC<CardTextProps> = ({
  children,
  className = "text-gray-common",
  depth = 0,
  liStyle = LiStyle.Disc,
}: CardTextProps) => {
  return (
    <ul
      className="list-disc list-outside ml-1 md:ml-2 pr-3 my-0.5 text-base"
      style={{ paddingLeft: `${1.5 + depth * 1.5}rem` }}
    >
      {liStyle === LiStyle.Disc ? (
        <Text.Body className={`text-base leading-tight ${className}`}>
          <li>{children}</li>
        </Text.Body>
      ) : (
        <Description className={`-ml-11 ${className}`}>{children}</Description>
      )}
    </ul>
  );
};

interface DescriptionProps extends HTMLAttributes<HTMLDivElement> {
  marker?: string;
  noPadding?: boolean;
}

const Description: FC<DescriptionProps> = ({
  className = "text-gray-common",
  children,
  marker = ">",
  noPadding = false,
}: DescriptionProps) => {
  const { langPrefix } = useContext(LangContext);
  const padding = noPadding
    ? ""
    : langPrefix === LangPrefix.Ko
    ? marker === "-"
      ? "pl-10.5 md:pl-11.5"
      : "pl-11"
    : marker === "-"
    ? "pl-11 md:pl-12"
    : "pl-11.5";
  return (
    <Text.Body
      className={`my-0.5 text-sm ${padding} ${className}`}
      style={{ textIndent: marker === "-" ? "-0.8rem" : "-1rem" }}
    >
      <span className="text-gray-common font-normal">{marker}</span>
      &nbsp;&nbsp;{langPrefix === LangPrefix.Ko ? null : <>&nbsp;</>}
      {children}
    </Text.Body>
  );
};

export interface TimestampCardProps {
  data: Story[];
}

export const TimestampCard: FC<TimestampCardProps> = ({
  data,
}: TimestampCardProps) => {
  const { color } = useContext(NavbarContext);
  const { langPrefix } = useContext(LangContext);
  return (
    <Card className="mx-2 pr-2">
      <div className="timestamp-card-grid">
        {data.map(
          ({ type = StoryType.Chat, time, summaryDescription }: Story, idx) =>
            type === StoryType.CEWarning ? (
              <Description
                key={idx}
                className={`-ml-4 text-gray-common md:col-span-2 w-full ${
                  langPrefix !== LangPrefix.Cht ? "italic" : ""
                }`}
                marker="-"
              >
                {storyTypeToLang(langPrefix, type)}
              </Description>
            ) : (
              [
                <CardText
                  key={idx}
                  className={`w-full ${
                    type !== StoryType.Chat && type !== StoryType.VN
                      ? "text-black-custom font-semibold"
                      : "text-gray-common"
                  }`}
                >
                  {storyTypeToLang(langPrefix, type)}
                  {time && ` (${time})`}
                </CardText>,
                <div
                  key={`${idx}description`}
                  className={`w-full ${
                    langPrefix === LangPrefix.Cht ? "pl-4" : ""
                  }`}
                >
                  {summaryDescription?.map((desc, idx) => (
                    <Description
                      className={`w-full ${
                        color && desc.color ? desc.color : "text-gray-common"
                      }`}
                      key={idx}
                      noPadding
                    >
                      {desc.text}
                    </Description>
                  ))}
                </div>,
              ]
            )
        )}
      </div>
    </Card>
  );
};

interface RowsFromItemProps {
  item: ChatItem | string;
  depth?: number;
}

const RowsFromItem: FC<RowsFromItemProps> = ({
  item,
  depth = 0,
}: RowsFromItemProps) => {
  const { color, text } = useContext(NavbarContext);
  return typeof item === "string" ? (
    <CardText depth={depth} className="w-full text-gray-common">
      {item}
    </CardText>
  ) : (
    <>
      <CardText
        className={`w-full ${
          color && item.color ? item.color : "text-gray-common"
        }`}
        liStyle={
          item.type === ChatItemType.Description ||
          item.type === ChatItemType.Photo
            ? LiStyle.Inequal
            : LiStyle.Disc
        }
        depth={depth}
      >
        <span
          className={
            color && item.underline ? `underline ${item.underline}` : undefined
          }
        >
          {item.text}
        </span>
        {text && item.colorblind && (
          <span className="text-sm">
            {"\u00A0\u00A0"}⋯({item.colorblind})
          </span>
        )}
      </CardText>
      {item.subItems?.map((subItem, idx: number) => (
        <RowsFromItem key={idx} item={subItem} depth={depth + 1} />
      ))}
    </>
  );
};

export interface ChoiceCardProps {
  data: (ChatItem | string)[];
}

export const ChoiceCard: FC<ChoiceCardProps> = ({ data }: ChoiceCardProps) => (
  <div className="w-full flex items-center">
    <Card className="mx-2 py-1 pl-1 md:pl-0">
      {data.map((item, idx: number) => (
        <RowsFromItem key={idx} item={item} />
      ))}
    </Card>
  </div>
);

export interface PhotoCardProps {
  data: Photo;
  idx: number;
}

export const PhotoCard: FC<PhotoCardProps> = ({
  data,
  idx,
}: PhotoCardProps) => {
  const { langPrefix } = useContext(LangContext);
  const where = whereOfPhoto(langPrefix, data) ?? [];
  return (
    <div className="flex flex-col">
      <Text.Body className="text-sm text-gray-common">
        ({Math.floor(idx / 3) + 1}, {(idx % 3) + 1})
      </Text.Body>
      <Card className="flex flex-col text-center flex-grow w-full">
        <div className="flex-grow flex justify-center items-center w-full">
          <Text.Body
            className={`mx-2 my-1 text-gray-common font-semibold w-full ${
              langPrefix === LangPrefix.Ko
                ? "leading-tight text-sm md:text-base"
                : "leading-none md:leading-tight text-base"
            }`}
          >
            {textOfPhoto(langPrefix, data)}
          </Text.Body>
        </div>
        <Divider />
        <ul
          className={`flex-grow flex flex-col items-center justify-center space-y-1 my-1 w-full ${
            where && where.length > 1 ? "list-disc list-inside" : "list-none"
          }`}
        >
          {where.map((w, idx) => (
            <Text.Body
              key={idx}
              className="px-2 text-gray-common text-xs w-full"
            >
              <li>
                <span
                  className={`text-sm leading-none md:leading-tight ${
                    where.length > 1 ? "-ml-2" : ""
                  }`}
                >
                  {w}
                </span>
              </li>
            </Text.Body>
          ))}
        </ul>
      </Card>
    </div>
  );
};
